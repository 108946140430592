import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["favicon"];
  static values = { url: String };

  connect() {
    this.fetchFavicon();
  }

  fetchFavicon() {
    const url = this.urlValue.trim();

    if (!url) return;

    try {

      const domain = new URL(url).hostname;
      const faviconUrl = `https://icons.duckduckgo.com/ip3/${domain}.ico`

      this.faviconTarget.src = faviconUrl;
      this.faviconTarget.style.display = "inline"; // Ensure it's visible
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  }
}
