import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "hidden"];

  // Note: this replaces bootstraps data-toggle="buttons" functionality
  connect() {
    this.updateButtonStates();
  }

  toggle(event) {
    const radio = event.currentTarget;
    const label = radio.closest("label");

    if (!label) return;

    if (radio.checked && label.classList.contains("active")) {
      // Deselect if already selected
      radio.checked = false;
      label.classList.remove("active");

      // Select the hidden input
      this.hiddenTarget.checked = true;
    } else {
      // Deselect the hidden input
      this.hiddenTarget.checked = false;

      // Remove active class from all buttons, then add it to the selected one
      this.radioTargets
        .forEach(r => {
          r.closest("label").classList.remove("active")
          r.checked = false;
        });

      // Select the clicked button
      radio.checked = true;
      label.classList.add("active");
    }
  }

  updateButtonStates() {
    this.radioTargets.forEach(radio => {
      if (radio.checked) {
        radio.closest("label").classList.add("active");
      }
    });
  }
}
