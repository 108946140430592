import { Controller } from "@hotwired/stimulus"

// This currently only supports radio buttons, but could be expanded to support other types of inputs
// chatgpt is your friend :-)

export default class extends Controller {
  static targets = ["extraFields"]
  static values = { fieldName: String, triggers: Array }

  connect() {
    const checkedValue = this.element.querySelector(`input[name="${this.fieldNameValue}"]:checked`)?.value
    this.toggleForValue(checkedValue)
  }

  // This is a bit of a hack to make bootstrap radio buttong groups work because they don't trigger the change event on the input
  // Instead we listen for the click event on the label and then find the nested input and just call the toggleForValue method with the value
  toggle(event) {
    const nestedRadioButton = event.currentTarget.querySelector('input[type="radio"]')
    this.toggleForValue(nestedRadioButton.value)
  }

  toggleForValue(value) {
    this.extraFieldsTarget.style.display = this.triggersValue.includes(value) ? "block" : "none"
  }
}
