import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open() {
    $(this.element).modal("show");
  }

  close(event) {
    event.preventDefault();
    $(this.element).modal("hide");
  }

  // Cleanup modal backdrop and body classes before the page is cached
  teardown() {
    $(this.element).modal("hide");
    document.querySelector(".modal-backdrop")?.remove();
    document.body.classList.remove("modal-open");
  }
}
