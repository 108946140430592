import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { TreemapController, TreemapElement } from "chartjs-chart-treemap"

Chart.register(TreemapController, TreemapElement)

export default class extends Controller {
  static targets = ["tooltip", "tooltipTemplate", "canvas"]
  static values = {
    data: Array,
    labels: Array,
    abbreviatedLabels: Array,
    colors: Array,
    urls: Array,
    showNumbers: Boolean,
    peekUrls: Array,
    filterUrls: Array
  }

  connect() {
    this.hoveredIndex = null

    this.handleMouseMove = () => {
      if (!this.canvasTarget.matches(':hover') && !this.tooltipTarget.matches(':hover')) {
        this.hideTooltip()
      }
    }

    document.addEventListener("mousemove", this.handleMouseMove)
    Chart.register(ChartDataLabels)

    this.chart = new Chart(this.canvasTarget, {
      type: 'treemap',
      data: {
        datasets: [{
          data: this.dataValue,
          backgroundColor: ctx => {
            const baseColor = this.colorsValue[ctx.dataIndex]
            if (this.hoveredIndex != null && this.hoveredIndex !== ctx.dataIndex) {
              return this.darkenHexColor(baseColor, 0.4)
            }
            return baseColor
          },
          labels: {
            display: true,
            align: 'left',
            position: 'top',
            color: ['white', 'whiteSmoke'],
            font: [
              { size: 14, weight: 'bold', family: "'Cerebri Sans', sans-serif" },
              { size: 12 }
            ],
            hoverColor: 'white',
            formatter: ctx => [
              this.abbreviatedLabelsValue[ctx.dataIndex],
              ctx.raw.v
            ]
          }
        }]
      },
      options: {
        animation: false,
        plugins: {
          tooltip: {
            enabled: false,
            external: ctx => this.externalTooltipHandler(ctx)
          },
          legend: { display: false }
        },
        onHover: (event, elements) => {
          if (elements.length > 0) {
            this.hoveredIndex = elements[0].index
          }
          this.chart.update()
        }
      }
    })
  }

  externalTooltipHandler({ chart, tooltip }) {
    if (tooltip.opacity === 0 && !this.tooltipTarget.matches(':hover')) {
      this.hideTooltip()
      return
    }

    const point = tooltip.dataPoints?.[0]
    if (!point) return

    const { dataIndex } = point

    this.updateTooltipContent(dataIndex)

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
    this.tooltipTarget.style.opacity = 1
    this.tooltipTarget.style.left = positionX + tooltip.caretX - 100 + 'px'
    this.tooltipTarget.style.top = positionY + tooltip.caretY + 'px'
    this.tooltipTarget.style.font = tooltip.options.bodyFont.string
    this.tooltipTarget.style.padding = tooltip.options.padding + 'px'
  }

  hideTooltip() {
    this.tooltipTarget.style.opacity = 0
    this.tooltipTarget.style.left = "-9999px"
    this.tooltipTarget.style.top = "-9999px"
    this.hoveredIndex = null
    this.chart.update()
  }

  updateTooltipContent(dataIndex) {
    this.tooltipTarget.innerHTML = ""
    const content = this.tooltipTemplateTarget.content.cloneNode(true)
    content.querySelector("#peekLink").setAttribute('href', this.peekUrlsValue[dataIndex])

    const filterLink = content.querySelector("#filterLink")
    if (filterLink) {
      filterLink.setAttribute('href', this.filterUrlsValue[dataIndex])
    }

    content.querySelector("#label").textContent = this.labelsValue[dataIndex]
    this.tooltipTarget.appendChild(content)
  }

  darkenHexColor(hex, amount = 0.3) {
    const num = parseInt(hex.replace('#', ''), 16)
    let r = (num >> 16) & 255
    let g = (num >> 8) & 255
    let b = num & 255

    r = Math.floor(r * (1 - amount))
    g = Math.floor(g * (1 - amount))
    b = Math.floor(b * (1 - amount))

    return `rgb(${r},${g},${b})`
  }

  disconnect() {
    document.removeEventListener("mousemove", this.handleMouseMove)
  }
}
