// Entry point for the build script in your package.json

// Set Some Global Includes
import './jquery.js'

import "@hotwired/turbo-rails"
import 'bootstrap';
import ClipboardJS from 'clipboard';

require("trix")
require("@rails/actiontext")


import "./autosize.js";
import "./backbutton.js";
import "./external-links.js";
import "./list.js";
import "./organizations.js";
import "./person-edit-form.js";
import "./popover.js";
import "./pull-to-refresh.js";
import "./sortable.js";
import "./tooltip.js";
import "./turn.js";
import "./wizard.js";

import {
  initClipboardJS,
} from "./utils"

import "./controllers"

// Expose the Rails environment to the window object if it exists. We should only use this for debug messages as it is user editable.
window.RAILS_ENV = document.querySelector('meta[name="rails-env"]')?.getAttribute('content') || '';

// Turbo Patch for intercom
// https://github.com/intercom/intercom-rails/issues/336#issuecomment-929072944
// PATCH -->
document.addEventListener("turbo:submit-end", (event) => {
  window.turboFormSubmissionFailed = !event.detail.success

  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event('turbo:visit'))
    document.dispatchEvent(new Event('turbo:before-cache'))
  }
})
document.addEventListener("turbo:render", () => {
  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event('turbo:load'))
    window.turboFormSubmissionFailed = false
  }
})
// <-- PATCH

// This is a hack to make Turbo 7.3 work like id did in 7.2 i.e. if a frame is
// missing, just visit the URL directly. We relied on this behaviour in a few
// places where we submit a form from a modal and rerender the form on error and
// redirect on success. Without this the redirect doesn't work because the
// form's turbo frame is missing.
document.addEventListener("turbo:frame-missing", (event) => {
  // Log a deprecation error in the console in development
  if (window.RAILS_ENV === 'development') {
    console.error("DEPRECATION WARNING: The turbo frame is missing so we're visiting the URL directly using a hack. Please fix this by ensuring the frame is present.");
  }
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response);
});


document.addEventListener("turbo:load", initClipboardJS)

// Flash messages
document.addEventListener("turbo:before-cache", function () {
  $(".toast").remove()
})

document.addEventListener("turbo:load", function () {
  $('.toast').toast('show');
})

// Scroll error fields into view
document.addEventListener("turbo:load", function () {
  const errorField = document.querySelector('.field-with-errors');
  if (errorField) {
    setTimeout(function () {
      errorField.querySelector('label')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100)
  }

})

window.$ = $;
