import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inline-modal-trigger"
export default class extends Controller {
  static outlets = [ "inline-modal" ]

  open(event) {
    event.preventDefault()
    this.inlineModalOutlet.open()
  }
}
