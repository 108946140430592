import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="open-in-new-tab"
export default class extends Controller {
  connect() {
    // Add Target Blank to all links within the element
    this.element.querySelectorAll('a').forEach((link) => {
      // check case insensitively for post, delete to skip non-get links
      if (['DELETE', 'POST'].includes(link.getAttribute('data-turbo-method')?.toUpperCase())) return

      link.setAttribute('target', '_blank')
    })
  }
}
