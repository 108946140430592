import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    data: Array,
    labels: Array,
    urls: Array,
    chartType: { type: String, default: "bar" },
    showTicks: { type: Boolean, default: false }
  }

  static targets = ["canvas", "tooltip", "tooltipTemplate"]

  connect() {
    this.hoveredIndex = null

    if (this.hasTooltipTarget) {
      this.hideTooltipHandler = () => this.hideTooltip()
      this.tooltipTarget.addEventListener('mouseleave', this.hideTooltipHandler)
    }

    this.handleMouseMove = () => {
      if (!this.canvasTarget.matches(':hover') && !this.tooltipTarget.matches(':hover')) {
        this.hoveredIndex = null
        this.hideTooltip()
        this.chart.update()
      }
    }

    document.addEventListener("mousemove", this.handleMouseMove)

    const MIN_BAR_HEIGHT = 5;
    const baseColor = "#2C7BE5"

    this.chart = new Chart(this.canvasTarget, {
      type: this.chartTypeValue,
      data: {
        labels: this.labelsValue,
        datasets: [{
          data: this.dataValue,
          backgroundColor: ctx => {
            if (this.hoveredIndex != null && this.hoveredIndex !== ctx.dataIndex) {
              return this.darkenHexColor(baseColor, 0.4)
            }
            return baseColor
          },
          borderColor: baseColor,
          hoverBackgroundColor: baseColor,
          borderRadius: { topLeft: 5, topRight: 5, bottomLeft: 0, bottomRight: 0 },
          tension: 0.2
        }]
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
            external: ctx => this.externalTooltipHandler(ctx)
          },
        },
        scales: {
          x: {
            grid: { display: this.showTicksValue },
            ticks: { display: true }
          },
          y: {
            display: this.showTicksValue,
            grid: { display: this.showTicksValue },
            ticks: { display: this.showTicksValue },
            beginAtZero: true
          }
        },
        onHover: (event, elements) => {
          if (elements.length > 0) {
            this.hoveredIndex = elements[0].index
          } else if (!this.canvasTarget.matches(':hover') && !this.tooltipTarget.matches(':hover')) {
            this.hoveredIndex = null
          }
          this.chart.update()
        }
      },
      plugins: [{
        id: 'enforce-min-bar-height',
        beforeDatasetDraw: (chart, args) => {
          if (this.chartTypeValue !== "bar") return;

          const { ctx } = chart
          const dataset = chart.data.datasets[args.index]

          ctx.save()
          dataset.data.forEach((value, index) => {
            const meta = chart.getDatasetMeta(args.index)
            const bar = meta.data[index]

            if (bar) {
              const originalHeight = bar.height
              if (originalHeight < MIN_BAR_HEIGHT && value > 0) {
                const diff = MIN_BAR_HEIGHT - originalHeight
                bar.height = MIN_BAR_HEIGHT
                bar.y -= diff
              }
            }
          })
          ctx.restore()
        }
      }]
    })
  }

  externalTooltipHandler({ chart, tooltip }) {
    if (!this.hasTooltipTarget) return

    const tooltipEl = this.tooltipTarget
    if (tooltip.opacity === 0) {
      if (!tooltipEl.matches(':hover')) this.hideTooltip()
      return
    }

    const point = tooltip.dataPoints?.[0]
    if (!point) return

    const { dataIndex } = point
    this.updateTooltipContent(dataIndex)

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX - 100 + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY - 100 + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding = 10 + 'px ' + tooltip.options.padding + 'px'
  }

  updateTooltipContent(dataIndex) {
    this.tooltipTarget.innerHTML = ""
    const content = this.tooltipTemplateTarget.content.cloneNode(true)
    content.querySelector("#peekLink").setAttribute('href', this.urlsValue[dataIndex])

    const filterLink = content.querySelector("#filterLink")
    if (filterLink) {
      filterLink.setAttribute('href', this.urlsValue[dataIndex])
    }

    content.querySelector("#label").textContent = this.labelsValue[dataIndex]

    const valueString = this.dataValue[dataIndex] === 1 ? "item" : "items"
    content.querySelector("#value").textContent = `${this.dataValue[dataIndex]} ${valueString}`

    this.tooltipTarget.appendChild(content)
  }

  hideTooltip() {
    this.tooltipTarget.style.opacity = 0
    this.tooltipTarget.style.left = '-9999px'
    this.tooltipTarget.style.top = '-9999px'
  }

  darkenHexColor(hex, amount = 0.4) {
    const num = parseInt(hex.replace('#', ''), 16)
    let r = (num >> 16) & 255
    let g = (num >> 8) & 255
    let b = num & 255

    r = Math.floor(r * (1 - amount))
    g = Math.floor(g * (1 - amount))
    b = Math.floor(b * (1 - amount))

    return `rgb(${r},${g},${b})`
  }

  disconnect() {
    if (this.hasTooltipTarget) {
      this.tooltipTarget.removeEventListener('mouseleave', this.hideTooltipHandler)
    }
    document.removeEventListener("mousemove", this.handleMouseMove)
  }
}
