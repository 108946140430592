import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Connects to data-controller="charts--parties-chart"
export default class extends Controller {
  static values = { data: Array, labels: Array, colors: Array, urls: Array, showNumbers: Boolean }

  connect() {
    Chart.register(ChartDataLabels) // Register DataLabels plugin

    this.chart = new Chart(this.element, {
      type: 'pie',
      data: {
        datasets: [{
          data: this.dataValue,
          backgroundColor: this.colorsValue
        }],
        labels: this.labelsValue
      },
      options: {
        animation: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              generateLabels: (chart) => {
                const data = chart.data.datasets[0].data
                return chart.data.labels.map((label, i) => ({
                  borderRadius: 4,
                  text: this.showNumbersValue ? `${label} - ${data[i]}` : label,
                  fillStyle: chart.data.datasets[0].backgroundColor[i]
                }))
              }
            }
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const index = elements[0].index
            const url = this.urlsValue[index]
            if (url) {
              Turbo.visit(url, { frame: 'modal' })
            }
          }
        },
        onHover: (event, elements) => {
          this.element.style.cursor = elements.length > 0 ? "pointer" : "default"
        }
      }
    })
  }
}
