import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="social-embeds--facebook"
export default class extends Controller {
  static values = { appId: String }; // Pass your Facebook App ID as a data attribute

  connect() {
    this.loadFacebookSDK();
  }

  loadFacebookSDK() {
    if (window.FB) {
      // SDK is already loaded, just reinitialize
      this.reparse();
      return;
    }

    if (document.getElementById("facebook-jssdk")) return; // Avoid multiple loads

    let script = document.createElement("script");
    script.id = "facebook-jssdk";
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      window.FB.init({
        appId: this.appIdValue || "", // Use the appId from data attribute
        autoLogAppEvents: true,
        xfbml: true,
        version: "v22.0",
      });

      this.reparse();
    };

    document.body.appendChild(script);
  }

  reparse() {
    if (window.FB) {
      FB.XFBML.parse(this.element);
    }
  }
}
