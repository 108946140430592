import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-pill-spinner"
export default class extends Controller {

  static targets = [ "spinElement" ]

  connect() {
    this.spinnerHtml = '<span class="spinner-border spinner-border-sm text-secondary" role="status" data-filter-pill-spinner-target="spinElement"><span class="sr-only">Loading...</span></span>'
    this.originalElement = this.spinElementTarget.outerHTML
  }

  spin() {
    if (this.spinElementTarget.outerHTML !== this.spinnerHtml) {
      this.spinElementTarget.outerHTML = this.spinnerHtml
    }
  }

  teardown() {
    this.spinElementTarget.outerHTML = this.originalElement
  }
}
