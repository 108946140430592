import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["topPartiesChart", "topStakeholdersChart", "histogram", "fileInput", "form", "loadingButton", "downloadButton"]

  connect() {
    setTimeout(() => this.generateChartImages(), 500);
  }

  generateChartImages() {
    this.generateChartImage(this.topPartiesChartTarget, "top_parties_chart.png", "top_parties_chart");
    this.generateChartImage(this.topStakeholdersChartTarget, "top_stakeholders_chart.png", "top_stakeholders_chart");
    this.generateChartImage(this.histogramTarget, "histogram.png", "histogram");

    this.loadingButtonTarget.classList.remove("d-none");
    this.downloadButtonTarget.classList.add("d-none");

    setTimeout(() => this.submitForm(), 500);
  }

  generateChartImage(canvas, filename, fieldName) {
    if (!canvas) return;

    canvas.toBlob(blob => {
      if (!blob) return;

      const file = new File([blob], filename, { type: "image/png" });

      const fileInput = this.fileInputTargets.find(input => input.name.includes(fieldName));
      if (fileInput) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInput.files = dataTransfer.files;
      }
    }, "image/png", 1.0);
  }

  submitForm() {
    const formData = new FormData(this.formTarget);

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "downloaded_file.docx"; // Default filename

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }
      }

      return response.blob().then(blob => ({ blob, filename }));
    })
    .then(({ blob, filename }) => {
      this.triggerDownload(blob, filename);
      this.resetDownloadButton();
    })
    .catch(error => {
      console.error(error);
      alert("File download failed.");
      this.resetDownloadButton();
    });
  }

  triggerDownload(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Free memory
  }

  resetDownloadButton() {
    this.loadingButtonTarget.classList.add("d-none");
    this.downloadButtonTarget.classList.remove("d-none");
  }
}
