import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Connects to data-controller="charts--stakeholders-chart"
export default class extends Controller {
  static values = { data: Array, labels: Array, showNumbers: Boolean }

  connect() {
    Chart.register(ChartDataLabels)
    const maxLabelLength = 30;

    const truncatedLabels = this.labelsValue.map(label =>
      label.length > maxLabelLength ? label.substring(0, maxLabelLength) + "…" : label
    );

    this.chart = new Chart(this.element, {
      type: 'bar',
      data: {
        labels: truncatedLabels,
        datasets: [{
          label: '# of Mentions',
          data: this.dataValue,
          backgroundColor: '#244166',
          borderWidth: 1
        }]
      },
      options: {
        indexAxis: 'x', // Makes the chart horizontal
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: 10 },
        scales: {
          x: { beginAtZero: true },
          y: { ticks: { autoSkip: false } }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          datalabels: {
            display: this.showNumbersValue, // Show/hide numbers
            color: '#000',
            anchor: 'end',
            align: 'center',
            font: { weight: 'bold' },
            formatter: (value) => value
          }
        }
      }
    })
  }
}
