import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="emulation"
export default class extends Controller {
  static targets = ["countdown", "extendSessionForm", "logOutForm"]
  static values = { expiresAt: String }

  connect() {
    this.updateCountown()

    console.log(this.extendSessionFormTarget)

    this.interval = setInterval(() => {
      this.updateCountown()
      if (this.secondsRemaining() <= 0) {
        this.logOut()
        clearInterval(this.interval)
      } else if (this.secondsRemaining() <= 60) {
        if (confirm(" You will be logged out in 60 seconds, click OK to extend your session or Cancel to return to Admin.")) {
          clearInterval(this.interval)
          this.extendTime()
        } else {
          clearInterval(this.interval)
          this.logOut()
        }
      }
    }, 1000)
  }

  updateCountown() {
    // Format the time remaining as MM:SS
    const seconds = this.secondsRemaining()
    const formattedCountdown = `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, "0")}`

    this.countdownTarget.textContent = formattedCountdown
  }

  extendTime() {
    // If the user extends their session but the session has already expired, log them out, otherwise extend the session
    if (this.secondsRemaining() <= 0) {
      this.logOut()
    } else {
      this.extendSessionFormTarget.requestSubmit()
    }
  }

  logOut() {
    this.logOutFormTarget.requestSubmit()
  }

  secondsRemaining() {
    // Parse the expiresAtValue and calculate the number of seconds remaining
    const expiresAt = new Date(this.expiresAtValue)
    const now = new Date()
    return Math.floor((expiresAt - now) / 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
