import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.ensureTwitterScript().then(() => {
      this.embedTweet();
    });
  }

  // Loads the Twitter script if not already present and waits for twttr to be ready.
  ensureTwitterScript() {
    return new Promise((resolve, reject) => {
      // Already loaded and ready?
      if (window.twttr && window.twttr.widgets) {
        console.log("Twitter script already loaded");
        resolve();
        return;
      }

      // If the script tag exists but twttr isn’t ready yet, wait.
      let existingScript = document.getElementById("twitter-wjs");
      if (existingScript) {
        let checkInterval = setInterval(() => {
          if (window.twttr && window.twttr.widgets) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 50);
        return;
      }

      // Otherwise, create and insert the script tag.
      const script = document.createElement("script");
      script.id = "twitter-wjs";
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.charset = "utf-8";
      script.onload = () => {
        // Poll until twttr is really available.
        let checkInterval = setInterval(() => {
          if (window.twttr && window.twttr.widgets) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 50);
      };
      script.onerror = reject;

      document.head.appendChild(script);
    });
  }

  // Once Twitter is ready, load just this element’s embedded tweet(s).
  embedTweet() {
    if (window.twttr && window.twttr.widgets) {
      console.log("Embedding tweet(s) in", this.element);
      window.twttr.widgets.load(this.element);
    }
  }
}
